<script>
import Top from "@/components/Top.vue";
import Foot from "@/components/Foot.vue";
import HomePage from "@/components/home/HomePage.vue";
import Box from "@/components/Box.vue";

export default {
  name: 'App',
  components: {
    Top, Foot, HomePage, Box
  },
  methods: {},
  beforeCreate() {
  },
  mounted() {
    let lang = localStorage.getItem("lang");

    if (lang === null){
      lang = "zh";
    }
    localStorage.setItem("lang",lang)
  }
}
</script>

<template>
  <div id="app">
    <Top></Top>
    <router-view></router-view>
    <box></box>
    <Foot></Foot>
  </div>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

</style>
