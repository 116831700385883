<script>
import common from "@/assets/js/common";

export default {
  name: "Top",
  data() {
    return {
      classObj: {
        header: true,
        mini: false
      },
      chinese: true
    }
  },
  methods: {
    getActiveResult(path) {
      return this.$route.path.includes(path)
    },
    translate(lang, flag) {
      if (lang !== this.$i18n.locale) {
        window.location.reload();
      }
      console.log("设置lang为",lang);
      localStorage.setItem("lang", lang)
      this.$i18n.locale = lang
      this.chinese = flag
    },
  },
  mounted() {
    window.addEventListener('scroll', () => {
      this.classObj.mini = window.scrollY > 0
    });
  }
}
</script>

<template>
  <div>
    <div :class="classObj" id="head">

      <div class="container_wrap clearfix">
        <div class="logo fl" style="width:145px;left:5%;">
          <img src="@/assets/logo.png" alt="合肥市耀匙电子产品有限公司" title="合肥市耀匙电子产品有限公司"
               class="index_logo"
               @click="$router.go(0)">
        </div>
        <div class="nav fr nav_bar">
          <ul class="nav_pc clearfix yellow">
            <li :class="{active :getActiveResult('home')}">
              <router-link to="/home">{{ $t("msg.top.home") }}</router-link>
            </li>
            <li :class="{active :getActiveResult('product')}">
              <router-link to="/product">{{ $t("msg.top.product") }}</router-link>
            </li>
            <li :class="{active : getActiveResult('software')}">
              <router-link :to="{
              path:'/software',
              query:{
                isShow:true
              }
            }">{{ $t("msg.top.software") }}
              </router-link>
            </li>
            <li :class="{active : getActiveResult('tutorial')}">
              <router-link to="/tutorial">{{ $t("msg.top.tutorial") }}</router-link>
            </li>
            <li :class="{active : getActiveResult('about')}">
              <router-link to="/about">{{ $t("msg.top.about") }}</router-link>
            </li>
            <div class="top_language">
              <a @click.prevent="translate('zh',true)" :class="{active:this.$i18n.locale === 'zh'}">中文</a>
              <a @click.prevent="translate('en',false)" :class="{active:this.$i18n.locale === 'en'}">EN</a>
            </div>
          </ul>

        </div>
      </div>
    </div>
    <div class="moblie_header ">
      <div class="container clearfix">
        <div class="fl">
          <a @click.prevent="$router.go(0)">
            <img src="@/assets/logo.png" alt="合肥市耀匙电子产品有限公司" title="合肥市耀匙电子产品有限公司">
          </a>
        </div>
        <div class="fr">
          <a @prevent class="menu">
            <img alt="" src="@/assets/menu.png">
          </a>
        </div>
      </div>
    </div>
    <div class="menu_box">
      <div class="menu_mark "></div>
      <div class="btn_closed_box">
        <a @click.prevent class="btn_closed">
          <img src="@/assets/close.png" width="22" alt="">
        </a>
      </div>
      <div class="menu_nav">
        <ul>
          <li class="had_sonnav">
            <router-link to="/home">{{ $t("msg.top.home") }}</router-link>
          </li>
          <li class="had_sonnav">
            <router-link to="/product">{{ $t("msg.top.product") }}</router-link>
          </li>
          <li class="had_sonnav">
            <router-link :to="{
              path:'/software',
              query:{
                isShow:true
              }
            }">{{ $t("msg.top.software") }}
            </router-link>
          </li>
          <li class="had_sonnav">
            <router-link to="/tutorial">{{ $t("msg.top.tutorial") }}</router-link>
          </li>
          <li class="had_sonnav">
            <router-link to="/about">{{ $t("msg.top.about") }}</router-link>
          </li>
          <li class="had_sonnav">
            <a @click.prevent="translate('zh',true)">中文</a>
          </li>
          <li class="had_sonnav">
            <a @click.prevent="translate('en',false)">EN</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style scoped>
.logo img {
  max-width: 100%;
  max-height: 65px;
  position: relative;
  z-index: 100;
  top: -13px
}


</style>